<template>
  <div class="reserve-email">
    <h3 class="title">
      Inserisci la mail per ricevere informazioni sul ritiro
    </h3>

    <div class="form-error" v-if="error.length > 0">
      <span>{{ error }}</span>
    </div>

    <form action="#" @submit="handleInput">
      <div class="input-control">
        <input
          autocomplete="off"
          placeholder="----"
          type="text"
          name="email"
          ref="emailInput"
          v-model="email"
          @input="onInputChange"
        />
        <button type="submit">{{ email.length > 0 ? "Next" : "Skip" }}</button>
      </div>
    </form>
    <SimpleKeyboard @onChange="onChange" :input="email" />
  </div>
</template>

<style lang="scss" scoped>
.reserve-email {
  width: 85%;
}
.reserve-email .simple-keyboard {
  transform: scaleY(1.4) translateY(10%);
}

h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;
}

.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input {
  width: 90%;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
}

input:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
}
</style>

<script>
import SimpleKeyboard from "@/components/AlphaKeyboard";

export default {
  name: "Leave/ReceiptEmail",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      email: "",
      error: "",
      polling: null,
    };
  },
  mounted() {
    this.polling = setInterval(() => {
      this.$refs.emailInput.focus();
    }, 0);
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  methods: {
    handleInput: function (e) {
      e.preventDefault();

      this.error = "";
      this.email = this.email.trim();

      if (this.email.length == 0) {
        this.$router.push({
          name: "Leave/Submit",
          query: {
            ...this.$route.query,
            receiptEmail: false,
          },
        });
        return;
      }

      //   if (this.email.length <= 0) {
      //     this.error = "Please enter the e-mail address.";
      //     return;
      //   }

      let isValid =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        );

      if (!isValid) {
        this.error = "This address doesn't seem valid.";
        return;
      }

      this.$router.push({
        name: "Leave/Submit",
        query: {
          ...this.$route.query,
          receiptEmail: this.email,
        },
      });
      return;
    },
    onChange(input) {
      this.email = input;
    },
    onInputChange(input) {
      this.email = input.target.value;
    },
  },
};
</script>
